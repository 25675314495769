import CTA from 'components/UI/Cta';
import Image from 'components/UI/Image';
import RichText from 'components/UI/Richtext';
import { ReactElement } from 'react';
import { IComponentHeroImageWithTextOverlayFields, IFragmentCta } from 'types/contentful';

export default function HeroImageWithTextOverlay({
  heading,
  copy,
  image,
  cta,
}: IComponentHeroImageWithTextOverlayFields): ReactElement {
  return (
    <div className="my-10 w-full relative lg:h-banner-desktop">
      <Image image={image} query="w=950&h=400" classNames="h-full w-full" />
      <div className="m-10 relative inset-0 flex items-center justify-center lg:absolute lg:m-0">
        <div className="relative content-center object-center lg:w-4/6">
          <RichText
            document={heading}
            classNames="text-center text-4xl font-medium leading-none font-heading lg:text-white lg:text-5xl xl:text-5xl "
          />
          <RichText
            document={copy}
            classNames="text-center text-base mt-6 lg:text-white lg:text-xl "
          />
          <div className="flex flex-col justify-center mx-auto max-w-xs lg:max-w-none lg:flex-row lg:flex-nowrap">
            {cta &&
              cta.map((ctaItem: IFragmentCta, i: number) => {
                return (
                  <CTA key={i} {...ctaItem.fields} classNames="grow mt-6 lg:mr-6 lg:text-white" />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
